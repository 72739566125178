<template>
  <div class="battery">
    <div class="title">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="设备编号">
          <el-input
            v-model="searchForm.number"
            clearable
            placeholder="请输入设备编号/电池二维码/通讯板编号"
            style="width:300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="所属组织">
          <AgentTree
            :props="props"
            v-model="searchForm.unitNo"
            placeholder="请选择组织"
            style="width: 300px"
          ></AgentTree>
        </el-form-item>
        <el-form-item label="处理状态">
          <el-select
            ref="searchFormDealStatus"
            v-model="searchForm.dealStatus"
            clearable
            placeholder="请选择处理状态"
          >
            <el-option
              v-for="opt in HANDLE_STATUS"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <div class="fr mb20">
        <el-button type="primary" @click="reportTrouble">报故障</el-button>
      </div>
    </div>
    <div>
      <el-table :data="list" style="width: 100%">
        <el-table-column prop="deviceNo" min-width="210" label="设备编号">
          <template slot="header">
            <p>设备编号</p>
            <p>电池二维码</p>
            <p>通讯板编号</p>
          </template>
          <template slot-scope="{ row }">
            <p><span class="c9">设备编号:</span><span class="blue">{{ row.deviceNo }}</span> </p>
            <p><span class="c9">电池二维码:</span><span class="blue">{{ row.linkNo }}</span> </p>
            <p><span class="c9">通讯板编号:</span><span class="blue">{{ row.deviceId }}</span> </p>
          </template>
        </el-table-column>
        <el-table-column prop="proxyName" label="代理商"  width="150"/>
        <el-table-column prop="shopName" label="门店" />
        <el-table-column prop="status" label="激活状态">
          <template slot-scope="{ row }">
            {{ $changeObj(ACTIVITY_STATUS)[row.activityStatus] }}
          </template>
        </el-table-column>
        <el-table-column prop="reasonType" label="故障原因" min-width="150">
          <template slot-scope="{ row }">
            {{ $changeObj(TROUBLE_REASON)[row.reasonType] }}
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注" min-width="150"/>
        <el-table-column prop="dealStatus" label="是否处理">
          <template slot-scope="{ row }">
            {{ $changeObj(HANDLE_STATUS)[row.dealStatus] }}
          </template>
        </el-table-column>
        <el-table-column prop="dealTime" label="处理时间" min-width="150">
          <template slot-scope="{row}">
            {{row.dealTime | timeToDate}}
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="添加时间" min-width="150">
          <template slot-scope="{row}">
            {{row.createTime }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" width="200" label="操作">
          <template slot-scope="scope">
            <el-button
              class="gray"
              type="text"
              @click="reinvent(scope.row)"
              v-if="scope.row.dealStatus == 1 && scope.row.deleteFlag == 0"
            >
              重新投入
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="报故障" :visible.sync="dialogVisible" width="30%">
      <el-form
        class="form"
        ref="troubleForm"
        :model="troubleForm"
        :rules="rules"
        label-width="150px"
      >
        <el-form-item label="电池编号" prop="number">
          <el-input
            v-model="troubleForm.number"
            clearable
            placeholder="请输入设备编号/电池二维码/通讯板编号"
            style="width:200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="故障类型" prop="reasonType">
          <el-select
            v-model="troubleForm.reasonType"
            clearable
            placeholder="请选择故障类型"
            style="width:200px"
          >
            <el-option
              v-for="opt in TROUBLE_REASON"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            v-model="troubleForm.remark"
            clearable
            placeholder="请输入备注"
            style="width:200px"
            type="textarea"
            :rows="2"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveTrouble">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getListTrouble, reportTrouble, againUsed } from "@/api/facility";
import { ACTIVITY_STATUS, HANDLE_STATUS, TROUBLE_REASON } from "@/js/constant";
import { timeToDate } from "@/utils/date-format";
import AgentTree from "@/components/AgentTree";
export default {
  data() {
    return {
      searchForm: {
        number: "", // 设备编号
        unitNo: [], // 组织
        dealStatus: "" //处理状态
      },
      ACTIVITY_STATUS,
      HANDLE_STATUS,
      TROUBLE_REASON,
      beginIndex: 1,
      pageSize: 20,
      total: 0,
      list: [],
      // 弹出层
      dialogVisible: false,
      loading: false,
      troubleForm: {
        number: "",
        reasonType: "",
        remark: ""
      },
      rules: {
        number: [
          {
            required: true,
            message: "请输入设备编号/电池二维码/通讯板编号",
            trigger: "blur"
          }
        ],
        reasonType: [
          { required: true, message: "请选择故障类型", trigger: "change" }
        ]
      },
      props: {
        value: "unitNo",
        label: "name",
        checkStrictly: true
      }
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.$refs.searchFormDealStatus.blur()
        _this.onSearch()
      }
    };
    this.getListTrouble();
  },
  methods: {
    onSearch() {
      this.beginIndex = 1;
      this.getListTrouble();
    },
    async getListTrouble() {
      let page = this.beginIndex;
      page = page - 1;
      const params = {
        ...this.searchForm,
        beginIndex: page,
        pageSize: this.pageSize
      };
      const unitNo = this.searchForm.unitNo[this.searchForm.unitNo.length - 1];
      params.unitNo = unitNo;
      const res = await getListTrouble(params);
      res.ldata.forEach(el => {
        if (el && el.createTime && el.createTime != "null") {
          el.createTime = timeToDate(el.createTime);
        }
        if (el) {
          el.loading = false;
        }
      });
      this.list = res.ldata;
      this.total = +res.mdata.total || 0;
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.getListTrouble();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },
    /**打开弹窗-报故障 */
    reportTrouble() {
      this.dialogVisible = true;
    },
    async saveTrouble() {
      this.$refs.troubleForm.validate(async valid => {
        if (valid) {
          if (this.loading) {
            return;
          }
          this.loading = true;
          const params = this.troubleForm;
          try {
            await reportTrouble(params);
            this.dialogVisible = false;
            this.loading = false;
            this.$message.success("报故障成功");
            this.onSearch();
            this.$refs.troubleForm.resetFields();
          } catch (error) {
            this.loading = false;
            this.$refs.troubleForm.resetFields();
          }
        }
      });
    },
    /**重新投入 */
    async reinvent(row) {
      const params = {
        haiLiDeviceNo: row.deviceNo
      };
      try {
        await againUsed(params);
        this.$message.success("重新投入成功");
        this.onSearch();
      } catch (error) {
        console.log(error);
      }
    }
  },
  components: {
    AgentTree
  }
};
</script>

<style scoped lang="less">
.battery {
  padding-bottom: 80px;
}
</style>
