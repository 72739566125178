<template>
  <div class="battery">
    <div class="title">
      <el-form :inline="true" :model="searchForm" class="demo-form-inline">
        <el-form-item label="设备编号">
          <el-input
            v-model="searchForm.number"
            clearable
            placeholder="请输入设备编号/电池二维码/通讯板编号"
            style="width:300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="产品型号">
          <ProductNo
            v-model="searchForm.productNo"
            placeholder="请选择产品型号"
            clearable
             ref="product_no"
            filterable
          ></ProductNo>
        </el-form-item>
        <el-form-item label="工作状态">
          <el-select
            ref="searchFormWorkStatus"
            v-model="searchForm.workStatus"
            clearable
            placeholder="请选择工作状态"
          >
            <el-option
              v-for="opt in WORK_STATUS"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="在线状态">
          <el-select
            ref="searchFormStatus"
            v-model="searchForm.status"
            clearable
            placeholder="请选择在线状态"
          >
            <el-option
              v-for="opt in STATUS"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="BMS类型">
          <el-select
            ref="searchFormBmsType"
            v-model="searchForm.bmsType"
            clearable
            placeholder="请选择BMS类型"
          >
            <el-option
              v-for="opt in BMS_BOARD_TYPE"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="激活状态">
          <el-select
            ref="searchFormActivityStatus"
            v-model="searchForm.activityStatus"
            clearable
            placeholder="请选择激活状态"
          >
            <el-option
              v-for="opt in ACTIVITY_STATUS"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="电池状态">
          <el-select
            ref="searchFormBatteryStatus"
            v-model="searchForm.batteryStatus"
            clearable
            placeholder="请选择电池状态"
          >
            <el-option
              v-for="opt in BATTERY_STATUS"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="定位状态">
          <el-select
            ref="searchFormPositionStatus"
            v-model="searchForm.positionStatus"
            clearable
            placeholder="请选择定位状态"
          >
            <el-option
              v-for="opt in POSITION_STATUS"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择组织">
          <AgentTree
            :props="props"
            v-model="searchForm.unitNo"
            placeholder="请选择组织"
            style="width: 300px"
          ></AgentTree>
        </el-form-item>
        <el-form-item label="租赁状态">
          <el-select
            ref="searchFormLeaseStatus"
            v-model="searchForm.leaseStatus"
            clearable
            placeholder="请选择租赁状态"
          >
            <el-option
              v-for="opt in LEASE_STATUS"
              :key="opt.value"
              :value="opt.value"
              :label="opt.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="放电开关">
          <el-select
            ref="searchFormMosSwitch"
            v-model="searchForm.mosSwitch"
            clearable
            placeholder="请选择放电开关"
          >
            <el-option :value="0" label="关"></el-option>
            <el-option :value="1" label="开"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <div class="fr mb20">
        <el-button type="primary" @click="closeBattery">关闭放电开关</el-button>
        <el-button type="primary" @click="disEquipment" icon="el-icon-success" class="bkeee bdeee black">分配设备</el-button>
        <el-button type="danger" @click="delNewBattery('', 'all')" icon="el-icon-success"
          >删除设备</el-button
        >
      </div>
    </div>
    <div>
      <el-table
        :data="list"
        style="width: 100%"
        @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" width="55"/>
        <el-table-column prop="deviceNo" min-width="210" label="设备编号">
          <template slot="header">
            <p>设备编号</p>
            <p>电池二维码</p>
            <p>通讯板编号</p>
          </template>
          <template slot-scope="{ row }">
            <p><span class="c9">设备编号:</span> <span class="blue">{{ row.deviceNo }}</span></p>
            <p><span class="c9">电池二维码:</span><span class="blue">{{ row.linkNo }}</span> </p>
            <p><span class="c9">通讯板编号:</span><span class="blue">{{ row.deviceId }}</span> </p>
          </template>
        </el-table-column>
        <el-table-column prop="agentStr" label="代理商" min-width="140"/>
        <el-table-column prop="shopStr" label="门店" />
        <el-table-column prop="status"  label="激活状态" width="80">
          <template slot-scope="{ row }">
            <span :class="[{'bk bkblue blue':row.activityStatus},{'bdblue ':+row.activityStatus === 2}]">
              {{ $changeObj(ACTIVITY_STATUS)[row.activityStatus] }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="在线状态" width="50">
          <template slot-scope="{ row }">
            <span  :class="{'red':+row.status === 0}">
              {{ $changeObj(STATUS)[row.status] }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="productModel" label="产品型号" min-width="100"/>
        <el-table-column prop="bmsType" label="BMS板" width="80">
          <template slot-scope="{ row }">
            <span  :class="[{'bkorange bk orange':row.bmsType},{'bkorageDeep bdorange':+row.bmsType ===2}]">
              {{ $changeObj(BMS_BOARD_TYPE)[row.bmsType] }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="workStatus" label="工作状态" width="50">
          <template slot-scope="{ row }">
            {{ $changeObj(WORK_STATUS)[row.workStatus] }}
          </template>
        </el-table-column>
        <el-table-column prop="leaseStatus" label="租赁状态" width="70">
          <template slot-scope="{ row }">
            {{ $changeObj(LEASE_STATUS)[row.leaseStatus] }}
          </template>
        </el-table-column>
        <el-table-column prop="leaseEndTime" label="租赁到期时间" width="100"/>
        <el-table-column prop="batteryStatus" label="电池状态" width="50">
          <template slot-scope="{ row }">
            {{ $changeObj(BATTERY_STATUS)[row.batteryStatus] }}
          </template>
        </el-table-column>
        <el-table-column prop="publishStatus" label="发布状态" width="70">
          <template slot-scope="{ row }">
            {{ $changeObj(PUBLISH_STATUS)[row.publishStatus] }}
          </template>
        </el-table-column>
        <el-table-column prop="soc" label="电池电量" width="50">
          <template slot-scope="{ row }"> {{ row.soc || '0%' }} </template>
        </el-table-column>
        <el-table-column fixed="right" width="230" label="操作">
          <template slot-scope="scope">
            <div class="btnTopBox mb10">
              <el-button type="primary" size="mini"  v-if='scope.row.activityStatus == 2' @click="viewDetail(scope.row)"
              >详情</el-button
              >
              <!-- TO DO -->
              <!-- <el-button type="primary" size="mini" @click="edit(scope.row)">查看轨迹</el-button> -->
              <el-button
                class="green bkgreenshallow bdgreen"
                type="primary" size="mini"
                @click="getOneBattery(scope.row, scope.$index)"
                :loading="scope.row.loading"
                >更新数据</el-button
              >
              <el-button class="bdorange bkorange orange"  type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
            </div>
            <div class="btnBottomBox">
              <el-button size="mini" @click="delNewBattery(scope.row)"
              >删除</el-button
              >
              <el-button class="bdpurple bkpurple" type="primary" size="mini" @click="batteryTrack(scope.row)">查看轨迹</el-button>
            </div>


          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="fr mt20">
      <el-pagination
        @current-change="handleCurrentChange"
        :current-page.sync="beginIndex"
        :page-size="pageSize"
        @size-change="handleSizeChange"
        :page-sizes="[10, 20, 30, 50, 100, 150, 200]"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="分配设备" :visible.sync="dialogVisible" width="30%">
      <el-form>
        <el-form-item label="选择分配对象">
          <AgentTree
            :props="props"
            :extra="{ isContainHaiLiUnit: 2 }"
            v-model="unitNos"
            placeholder="请选择组织"
            style="width: 300px"
          ></AgentTree>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="distributeBattery">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  getListOutbound,
  delNewBattery,
  getOneBattery,
  distributeBattery
} from "@/api/facility";
import AgentTree from "@/components/AgentTree";
import ProductNo from "@/components/ProductNo";

import {
  PRODUCT_TYPE,
  STATUS,
  WORK_STATUS,
  BMS_BOARD_TYPE,
  ACTIVITY_STATUS,
  POSITION_STATUS,
  PUBLISH_STATUS,
  BATTERY_STATUS,
  LEASE_STATUS
} from "@/js/constant";
import { timeToDate } from "@/utils/date-format";
import { dischargeMosSwitch } from "@/api/product";

export default {
  data() {
    return {
      searchForm: {
        number: "", // 设备编号
        productNo: "", // 产品型号
        status: "", // 工作状态
        workStatus: "", // 工作状态
        bmsType: "", // BMS类型
        activityStatus: "", // 激活状态
        batteryStatus: "", // 电池状态
        positionStatus: "", //定位状态
        unitNo: [], // 组织
        leaseStatus: "", //租赁状态
        mosSwitch: "" //放电开关
      },
      PRODUCT_TYPE,
      STATUS,
      WORK_STATUS,
      BMS_BOARD_TYPE,
      ACTIVITY_STATUS,
      BATTERY_STATUS,
      POSITION_STATUS,
      PUBLISH_STATUS,
      LEASE_STATUS,
      beginIndex: 1,
      pageSize: 10,
      total: 1,
      list: [],
      /**选择的设备 */
      selectList: [],
      // 弹出层
      dialogVisible: false,
      /** 产品型号 */
      productNo: "",
      loading: false,
      props: {
        value: "unitNo",
        label: "name",
        checkStrictly: true
      },
      unitNos: []
    };
  },
  beforeDestroy() {
    document.onkeydown = null
  },
  mounted() {
    const _this = this;
    document.onkeydown = function(e) {
      let key = window.event.keyCode;
      if (key == 13) {
        _this.$refs.searchFormWorkStatus.blur()
        _this.$refs.searchFormStatus.blur()
        _this.$refs.searchFormBmsType.blur()
        _this.$refs.searchFormActivityStatus.blur()
        _this.$refs.searchFormBatteryStatus.blur()
        _this.$refs.searchFormPositionStatus.blur()
        _this.$refs.searchFormLeaseStatus.blur()
        _this.$refs.searchFormMosSwitch.blur()
        _this.$refs.product_no.blur()
        _this.onSearch()
      }
    };
    this.getListOutbound();
  },
  beforeRouteEnter (to, from, next) {
    if (from.path === '/leaseholdMgmt/batteryView' || from.path === '/leaseholdMgmt/addNewBattery') {
      to.meta.isBack = true;
    } else {
      to.meta.isBack = false
    }
    next()
  },
  activated () {
    if (this.$route.meta.isBack) {
      this.$route.meta.isBack = false // 重置详情页标识isBack
    }
      this.getListOutbound();
  },
  methods: {
    // 关闭放电开关
    closeBattery(){
      if(this.selectList.length === 0){
        this.$message.error('请勾选电池');
        return;
      }
       this.$confirm('是否确定关闭选中设备放电开关?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
           this.dischargeMosSwitch();
        })
    },
    // 关闭按钮请求
     async dischargeMosSwitch(){
      let str = '';
      this.selectList.forEach(el=>{
         str +=  `${el.deviceNo},`;
      })
      try{
        const params = {
          numbers:str
        };
        const res = await dischargeMosSwitch(params);
        if(res.result == 1){
          this.$message({
            type: 'success',
            message: res.message
          });
        }else{
          this.$message({
            type: 'error',
            message: res.message
          });
        }

      }catch(e){
        console.log(e,'e');
      }
    },
    // 查看轨迹
    batteryTrack(row){
      // console.log(row,'row');
      this.$router.push({
        path:'/leaseholdMgmt/batteryTrack',
        query:{
          haiLiDeviceNo:row.deviceNo
        }
      });
    },
    onSearch() {
      this.beginIndex = 1;
      this.getListOutbound();
    },
    async getListOutbound() {
      let page = this.beginIndex;
      page = page - 1;
      const params = {
        ...this.searchForm,
        beginIndex: page,
        pageSize: this.pageSize
      };
      const unitNo = this.searchForm.unitNo[this.searchForm.unitNo.length - 1];
      params.unitNo = unitNo;
      const res = await getListOutbound(params);
      res.ldata.forEach(el => {
        if (el.createTime == "null") {
          el.createTime = null;
        }
        if (el.createTime && el.createTime != "null") {
          el.createTime = timeToDate(el.createTime);
        }
        // if(el.leaseEndTime) {
        //     el.leaseEndTime = timeToDate(el.leaseEndTime);
        // }
        el.loading = false;
      });
      this.list = res.ldata;
      this.total = +res.mdata.total || 0;
    },
    /** 分页 */
    handleCurrentChange(page) {
      this.beginIndex = page;
      this.getListOutbound();
    },
    /**删除 */
    delNewBattery(row, type) {
      if (type == "all" && !this.selectList.length) {
        this.$message.error("请选择设备");
        return;
      }
      this.$confirm("是否确定删除电池?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(async () => {
          const params = {
            haiLiDeviceNoList: JSON.stringify([row.deviceNo])
          };
          if (type == "all") {
            params.haiLiDeviceNoList = JSON.stringify(
              this.selectList.map(el => el.deviceNo)
            );
          }
          await delNewBattery(params);
          this.$message.success("删除成功");
          this.beginIndex = 1;
          this.getListOutbound();
        })
        .catch(() => {});
    },
    /** 选择 */
    handleSelectionChange(row) {
      this.selectList = row;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.onSearch();
    },

    /**打开弹窗-分配设备 */
    disEquipment() {
      if (!this.selectList.length) {
        this.$message.error("请选择设备");
        return;
      }
      this.dialogVisible = true;
    },
    async distributeBattery() {
      if (this.loading) {
        return;
      }
      if (!this.unitNos.length) {
        this.$message.error("请选择组织");
        return;
      }
      this.loading = true;
      const params = {
        haiLiDeviceNoList: JSON.stringify(
          this.selectList.map(el => el.deviceNo)
        )
      };
      const unitNo = this.unitNos[this.unitNos.length - 1];
      params.unitNo = unitNo;
      try {
        await distributeBattery(params);
        this.dialogVisible = false;
        this.$message.success("分配成功");
        this.loading = false;
        this.onSearch();
      } catch (error) {
        this.loading = false;
      }
    },
    /**编辑 */
    edit(row) {
      this.$store.dispatch('login/checkHandle', '/managementPlatform/device/status/updateInfo' ).then((flag) => {
        if(flag) {
          this.$router.push({
            path: "/leaseholdMgmt/addNewBattery",
            query: {
              type: "edit",
              deviceNo: row.deviceNo,
              productNo: row.productNo,
              linkNo: row.linkNo,
              deviceId: row.deviceId,
              routerType: "list"
            }
          });
        } else {
          this.$message.error('暂无权限！')
        }
      }).catch(() => {
        this.$router.push({
          path: "/leaseholdMgmt/addNewBattery",
          query: {
            type: "edit",
            deviceNo: row.deviceNo,
            productNo: row.productNo,
            linkNo: row.linkNo,
            deviceId: row.deviceId,
            routerType: "list"
          }
        });
      })

    },
    /** 获取单个电池信息 */
    async getOneBattery(row, index) {
      if (this.loading) {
        return;
      }
      row.loading = true;
      const params = {
        haiLiDeviceNo: row.deviceNo
      };
      try {
        const res = await getOneBattery(params);
        const newRow = res.ldata[0];
        newRow.createTime = timeToDate(newRow.createTime)
        newRow.loading = false;
        this.$set(this.list, index, newRow);
      } catch (error) {
        row.loading = false;
      }
    },
    /**查看详情 */
    viewDetail(row) {
      this.$router.push({
        path: "/leaseholdMgmt/batteryView",
        query: {
          deviceNo: row.deviceNo,
          back:this.$route.fullPath

        }
      });
    }
  },
  components: {
    AgentTree,
    ProductNo
  }
};
</script>

<style scoped lang="less">
.battery {
  padding-bottom: 80px;
}
.btnBottomBox{
  display:flex;
}
.btnTopBox{
  display:flex;
}
</style>
