import axios from "../js/ajax";
/** 获取代理商和门店树 */
export const queryHaiLiUnitTree = params =>
  axios.get("/managementPlatform/operation/queryHaiLiUnitTree", { params });
/** 获取代理商/门店列表 */
export const queryHaiLiAgent = params =>
  axios.get("/managementPlatform/operation/queryHaiLiUnitList", { params });
/** 资金方，代理商和门店明细 */
export const queryHaiLiUnitDetail = params =>
  axios.get("/managementPlatform/operation/queryHaiLiUnitDetail", { params });
