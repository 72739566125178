<template>
  <el-select
    v-model="productNo"
    placeholder="请选择产品型号"
    v-bind="$attrs"
    ref="pro"
    @change="getProductNo"
  >
    <el-option
      v-for="opt in productList"
      :key="opt.productNo"
      :value="opt.productNo"
      :label="opt.productModel"
    ></el-option>
  </el-select>
</template>

<script>
import { getProductList } from "@/api/product";
export default {
  props: {
    value: {
      default: '',
      productNo: '',
    },
    extra: {
      default: () => {}
    }
  },
  watch: {
    value: {
      handler(val) {
        this.productNo = val;
        this.getProductNo()
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      /** 产品列表 */
      productList: [],
    }
  },
  mounted(){
    this.getProductList()
  },
  methods: {
     /** 获取产品 */
    async getProductList() {
      const params = {
        beginIndex: 0,
        pageSize: 500,
        productType: 1,
        ...this.extra,
      };
      const res = await getProductList(params);
      this.productList = res.ldata;
    },
    getProductNo() {
      this.$emit('input', this.productNo)
      this.$emit('change', this.productNo)
    },
    blur() {
      this.$refs.pro.blur()
    }
  },
  components: {

  }
}
</script>

<style scoped>

</style>
