<template>
  <div>
     <el-cascader
        clearable
        v-model="unitNos"
        @change="sendVal"
        :options="agentList"
        v-bind="$attrs"
        style="width: 300px"
        placeholder="请选择代理商/门店"
      >
      </el-cascader>
  </div>
</template>

<script>
import { handleTree } from "@/utils/tool";
import { queryHaiLiUnitTree } from "@/api/common";
export default {
  props: {
    value: {
      default:  () => [],
    },
    extra: {
      default: () => {}
    },
    selectKey: {
      default: ''
    }
  },
  data() {
    return {
      unitNos: [],
      agentList: [],
      unitNoInfo: {},
    }
  },
  computed: {
    selectVal() {
      return {
        agentList: this.agentList,
        selectKey: this.selectKey,
      }
    }
  },
  watch: {
    value(val) {
      this.unitNos = val
      this.$emit('input', this.unitNos )
    },
    selectVal: {
      handler(obj) {
        const arr = []
        obj.agentList.forEach(ele => {
          if (ele.unitNo == obj.selectKey) {
            arr.push(ele.unitNo);
          }
          if (ele.children && ele.children.length) {
            ele.children.forEach(ele2 => {
              if (ele2.unitNo == obj.selectKey) {
                arr.push(ele.unitNo, ele2.unitNo);
              }
            });
          }
        });
        this.unitNos = arr
        this.sendVal()
      },
      immediate: true,
      deep: true,
    }
  },
  mounted() {
    this.queryHaiLiUnitTree()
  },
  methods: {
    sendVal() {
      this.$emit('input', this.unitNos )
    },
    async queryHaiLiUnitTree() {
      const params = {
        isContainHaiLiUnit: 1,
        ...this.extra,
      };
      const res = await queryHaiLiUnitTree(params);
      if (res.mdata.flag) {
        this.agentList =  handleTree(res.mdata.haiLiUnit.subUnit);
      } else {
        this.agentList = handleTree(res.ldata);
      }
      this.$emit('agentList', this.agentList)
    },

  },
  components: {

  }
}
</script>

<style scoped>

</style>
