import axios from "../js/ajax";
/** 新增电池列表 */
export const getNewBatteryList = params =>
  axios.get("/managementPlatform/device/status/list", {
    params,
    loading: true
  });
/** 新增电池 */
export const addNewBattery = params =>
  axios.post("/managementPlatform/device/status/create", params);
/** 电池出库列表 */
export const getListInbound = params =>
  axios.get("/managementPlatform/device/status/listInbound", {
    params,
    loading: true
  });
/** 电池列表 */
export const getListOutbound = params =>
  axios.get("/managementPlatform/device/status/listOutbound", {
    params,
    loading: true
  });
/** 故障电池列表 */
export const getListTrouble = params =>
  axios.get("/managementPlatform/device/troubleStatus/list", {
    params,
    loading: true
  });
/** 申报故障 */
export const reportTrouble = params =>
  axios.post("/managementPlatform/device/troubleStatus/create", params);
/** 上传文件 */
export const uploadFile = params =>
  axios.post("/managementPlatform/device/parseExcel", params);
/** 删除电池 */
export const delNewBattery = params =>
  axios.post("/managementPlatform/device/status/delete", params);
/** 测试通过 */
export const passTest = params =>
  axios.post("/managementPlatform/device/status/updatePublicStatus", params);
/** 修改电池型号 */
export const saveProduct = params =>
  axios.post("/managementPlatform/device/status/changeProduct", params);
/** 编辑电池 */
export const saveEdit = params =>
  axios.post("/managementPlatform/device/status/updateInfo", params);
/** 获取单个电池信息 */
export const getOneBattery = params =>
  axios.get("/managementPlatform/device/status/queryByNo", { params });
/** 分配设备 */
export const distributeBattery = params =>
  axios.post("/managementPlatform/device/status/distribute", params);
/** 获取白名单域名 */
export const getWhiteList = params =>
  axios.get("/electricity/getDomainList", { params });
/** 重新投入 */
export const againUsed = params =>
  axios.get("/managementPlatform/device/troubleStatus/update", { params });
/** 电池轨迹 */
export const getBatteryTrack = params =>
  axios.post("/managementPlatform/electricity/queryBatteryTrack", params);
  /** 获取电池详情 */
  export const getBatteryDetail = params => axios.get('/managementPlatform/device/queryHaiLiDeviceDetail', { params })
  /** 电池详情操作 开关**/
  export const handleBattery = params => axios.get('/managementPlatform/device/operateBattery', { params })
  
  /** 电池详情操作 充电 */
  export const handleChargeBattery = params => axios.get('/managementPlatform/device/chargeSwitchOperate', { params })
  /** 电池详情操作 放电 */

  export const handleDischargeBattery = params => axios.get('/managementPlatform/device/dischargeSwitchOperate', { params })
// 里程统计
export const mileageStatistics = params => axios.get('/managementPlatform/device/mileageStatistics', { params })
// 物联卡信息
export const lotCardInfo = params => axios.get('/managementPlatform/device/lotCardInfo', { params })
//BMS历史数据
export const bmsHistoryData = params => axios.get('/managementPlatform/device/bmsHistoryData', { params })
//查看轨迹搜索
export const travelDeviceLocation = params => axios.get('/managementPlatform/device/queryDeviceLocation', { params })
//电动车列表
export const bicycleList = params => axios.get('/managementPlatform/device/bicycle/list', { params })

//分配电动车
export const bicycleDistribute = (params) => axios({
  method:'post',
  url:'/managementPlatform/device/bicycle/distribute',
  headers:{
    'Content-Type':'application/json'
  },
  data:params
})

//删除电动车
export const bicycleDelete = params => axios({
  url:'/managementPlatform/device/bicycle/delete',
  method:'post',
  data:params,
  headers:{
    'Content-Type':'application/json'
  }
})
// 修改电动车型号
export const editChangeProduct = params => axios({
  url:'/managementPlatform/device/bicycle/changeProduct',
  method:'post',
  data:params,
  headers:{
    'Content-Type':'application/json'
  }
})
// 新增/修改电动车（确认按钮）
export const bicycleAddOrUpdate = params => axios({
  url:'/managementPlatform/device/bicycle/addOrUpdate',
  method:'post',
  data:params,
  headers:{
    'Content-Type':'application/json'
  }
})
//电动车详情(修改回显)
export const bicycleDetail = params => axios.get('/managementPlatform/device/bicycle/detail', { params })
//导出按钮
export const BMSExport = params => axios.get('/electricity/lease/data/bms/export', { params })

