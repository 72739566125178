/**
 * @param {(Array)} arr
 * @return {Array}
 */
//不处理根组织
export function handleTree(arr) {
  let data = arr;
  data.forEach(el => {
    if (!el.subUnit.length) {
      el.children = undefined;
    } else {
      el.children = el.subUnit;
      el.children.forEach(el2 => {
        if (!el2.subUnit.length) {
          el2.children = undefined;
        } else {
          [(el2.children = el2.subUnit)];
        }
      });
    }
  });
  return data;
}

/**
 * @param {(Array)} arr
 * @return {Array}
 */
//不处理根组织
export function handleEditTree(arr) {
  let data = [];
  if (arr.length == 3) {
    data = arr.slice(-1);
  }
  if (arr.length == 4) {
    data = arr.slice(-2);
  }
  return data;
}
